import { call, put, takeLatest, delay } from "redux-saga/effects";
import { get } from "lodash";
import superagent from "superagent";
import { push } from "connected-react-router/immutable";

import {
  setRedirectTo,
  fetchUserAuctionDataRequest,
} from "storefront/features/shared/components/Header/actions";
import * as actions from "./actions";
import * as productActions from "storefront/features/productGrid/actions";
import {
  createArrayOfProductsForHome,
  getRandomInt,
  EXISTS,
  STATUS_BAD_REQUEST,
} from "storefront/features/shared/utils";

export function* fetchAllProductsRequest(action) {
  const { page, loader, withDelay } = action.payload;
  withDelay && (yield delay(15000 + getRandomInt()));
  const request = () =>
    superagent.get(`/api/collections/all-auctions`).query({ page });
  const response = yield call(request);
  yield put(actions.fetchAllAuctionProductsRequestResult(response.body));
  yield put(productActions.setFlagUpdateProductGrid(false));
  yield put(actions.removeLoader({ loader }));
}

export function* fetchProductsForHomeRequest(action) {
  const { page, loader, withDelay, filter_products_by } = action.payload;
  withDelay && (yield delay(15000 + getRandomInt()));
  const request = () =>
    superagent.get(`/api/home`).query({ page, filter_products_by });
  const response = yield call(request);
  const { bid_auctions, products, page_params } = response.body;
  const data = {
    bid_auctions: createArrayOfProductsForHome(bid_auctions),
    products,
    page_params,
  };
  yield put(actions.fetchAllAuctionProductsRequestResult(data));
  yield put(productActions.setFlagUpdateProductGrid(false));
  yield put(actions.removeLoader({ loader }));
}

export function* fetchVendorProductsRequest(action) {
  const { vendor_id, loader, withDelay } = action.payload;
  withDelay && (yield delay(15000 + getRandomInt()));
  const request = () =>
    superagent.get(`/api/vendors/${vendor_id}/products`).query(action.payload);
  try {
    const response = yield call(request);
    yield put(actions.fetchVendorProductsRequestResult(response.body));
    yield put(productActions.setFlagUpdateProductGrid(false));
    yield put(actions.removeLoader({ loader }));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* postBidRequest(action) {
  const { data, redirectLink, callbackFunc } = action.payload;
  const { email } = data;
  const request = () => superagent.post(`/api/bids`).query(data);

  try {
    yield call(request);
    if (email) {
      yield call([localStorage, "setItem"], "Unregistered-User-Email", email);
    }
    if (callbackFunc) {
      yield call(callbackFunc);
    }
    yield put(fetchUserAuctionDataRequest()); // TO DO check!!!
  } catch ({ status, response }) {
    if (
      status === STATUS_BAD_REQUEST &&
      get(response, "body.code", "") === EXISTS
    ) {
      yield put(setRedirectTo(redirectLink));
      yield put(push("/account/login"));
    } else {
      console.log(response);
    }
  }
}

export function* fetchCustomerContactFormSubmit(action) {
  const { callback, email, name, phone, message } = action.payload;
  const data = {
      email,
      name,
      phone,
      body: message,
    },
    request = () => superagent.post(`/api/emails/contact_email`).send(data);
  try {
    yield call(request);
    callback(true);
  } catch (res) {
    callback(false);
  }
}

export function* fetchStaticPageFromPrismic(action) {
  const { uid, loader } = action.payload;
  const request = () =>
    superagent.get(`/api/pages`).query({ static_page_uid: uid });
  try {
    const response = yield call(request);
    const { document } = response.body;
    if (document !== null) {
      yield put(actions.saveStaticPageFromPrismic(document));
      yield put(actions.removeLoader({ loader }));
    } else {
      throw new Error("no data from prismic");
    }
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export default [
  takeLatest(actions.FETCH_ALL_PRODUCTS_REQUEST, fetchAllProductsRequest),
  takeLatest(
    actions.FETCH_PRODUCTS_FOR_HOME_REQUEST,
    fetchProductsForHomeRequest
  ),
  takeLatest(actions.POST_ANONYMOUS_BID, postBidRequest),
  takeLatest(
    actions.FETCH_CUSTOMER_CONTACT_FORM_SUBMIT,
    fetchCustomerContactFormSubmit
  ),
  takeLatest(
    actions.FETCH_STATIC_PAGE_FROM_PRISMIC,
    fetchStaticPageFromPrismic
  ),
  takeLatest(actions.FETCH_VENDOR_PRODUCTS_REQUEST, fetchVendorProductsRequest),
];
